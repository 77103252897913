import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "./components/container/PageLoader";
import { Route, Routes, useNavigate } from "react-router-dom";

import { AuthenticationGuard } from "./components/authentication-guard";
import AuthCallbackHandler from "./services/AuthCallbackHandler";

import { baselightTheme } from "./theme/DefaultColors";
import { ScrollToTop } from "./views/utilities/ScrollToTop";


/* ***Layouts**** */
import HeadBodyLayout from './layouts/headbody/HeadBodyLayout';
import FullLayout from './layouts/full/FullLayout';

/* ****Pages***** */
import StartPage from './views/freelancers-marketplace/StartPage';
import Freelancers from './views/freelancers-marketplace/Freelancers';
import Dashboard from './views/dashboard/Dashboard';
import ProfileSetup from './views/profile/ProfileSetup';
import Profile from './views/profile/Profile';
import Subscription from './views/profile/Subscription';
import FreelancerAdmin from './views/freelancer/FreelancerAdmin';
import Freelancer from './views/freelancers-marketplace/Freelancer';
import FreelancerBookings from './views/freelancer/FreelancerBookings';
import FreelancerBooking from './views/freelancer/FreelancerBooking';
import ClientBookings from './views/client/ClientBookings';
import InfoPremiumPlus from './views/freelancers-marketplace/InfoPremiumPlus';
import ClientNeeds from './views/client/ClientNeeds';
import ClientNeed from './views/client/ClientNeed';
import Needs from './views/needs/Needs';
import NeedLandingPage from './views/needs/NeedLandingPage';
import Offers from './views/client/Offers';
import Service from './views/freelancers-marketplace/Service';
import FreelancerLandingPage from './views/freelancers-marketplace/FreelancerLandingPage';
import NeedDetails from './views/needs/NeedDetails';
import PublishNeed from './views/client/PublishNeed';
import OfferDetail from './views/client/OfferDetail';
import ClientLandingPage from './views/freelancers-marketplace/ClientLandingPage';
import AboutLandingPage from './views/freelancers-marketplace/AboutLandingPage';
import CreateOfferStandAlone from './views/offer/CreateOfferStandAlone';
import ShowOffer from './views/offer/ShowOffer';
import ShowOffers from './views/offer/ShowOffers';
import CreateAndUpdateInvoice from './views/invoice/CreateAndUpdateInvoice';
import ShowInvoices from './views/invoice/ShowInvoices';

import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";


function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Josefin Sans:wght@300;400;500;600', 'sans-serif']
      }
    });
   }, []);
   
  const { isLoading } = useAuth0();
  const theme = baselightTheme;
  
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  const RedirectToExternalUrl = ({ url }) => {

    useEffect(() => {
      window.location.href = url;
    }, [url]);

    return null;
  };


  const initializeHotjar = () => {
    const siteId = 3857049;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  };

  const initializeGA4 = () => {
    const measurementId = "G-TMTFG45ERT";
    ReactGA.initialize(measurementId);
  }

  const initializeMetaPixel = () => {
    !function(f,b,e,v,n,t,s) {
      if(f.fbq)return; n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
      n.queue=[]; t=b.createElement(e); t.async=!0;
      t.src=v; s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)
    }(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '755238836204464');
    fbq('track', 'PageView');
  };

  
  const consentGiven = getCookieConsentValue("bmConsent");
  if (consentGiven === "true") {
    initializeHotjar();
    initializeGA4();
    initializeMetaPixel();
  }


  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScrollToTop />
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="bmConsent"
            style={{ background: "#2B373B", fontFamily: 'Josefin Sans, sans-serif' }}
            buttonStyle={{ backgroundColor: "#ffffff", color: "#000000", fontSize: "15px", fontFamily: 'Josefin Sans, sans-serif', padding: '15px'  }}
            declineButtonStyle={{ backgroundColor: theme.palette.secondary.main, color: "#000000", fontSize: "15px", fontFamily: 'Josefin Sans, sans-serif'  }}
            expires={150}
            onAccept={() => {
              initializeHotjar();
              initializeGA4();
              initializeMetaPixel();
            }}
            onDecline={() => {
              if (window.hj) {
                window.hj('optOut');
              }
            }}
          >
            This website uses cookies to enhance the user experience. 
            Your consent applies to the domain: becausemondays.com. <a href="https://becausemondays.notion.site/Because-Mondays-Privacy-Policy-61ac7168767941f8a5bf6ff184a759d6" target="_blank" style={{ color: 'white' }}>Read our Privacy Policy</a>
          </CookieConsent>
          <Routes>
          <Route path="/authorize" element={<AuthCallbackHandler />} />
          <Route path="/callback" element={<AuthCallbackHandler />} />
            <Route element={<HeadBodyLayout />} >
              <Route path="/" element={<StartPage />} />
              <Route path="/freelancers" element={<Freelancers />} />
              <Route path="/freelancers/:searchTerm" element={<Freelancers />} />
              <Route path="/freelancer/:freelancer_id" element={<Freelancer />} />
              <Route path="/service/:service_id" element={<Service />} />
              <Route path="/freelancer-subscription" element={<FreelancerLandingPage />} />
              <Route path="/client-subscription" element={<ClientLandingPage />} />
              <Route path="/about" element={<AboutLandingPage />} />
              <Route path="/premiumplus" element={<InfoPremiumPlus />} />
              <Route path="/freelancer-offer/:unique_identifier" element={<ShowOffer />} />
              <Route
                path="/plaza/profile-setup"
                element={<AuthenticationGuard component={ProfileSetup} />}
              />
              <Route path="/project/:needId" element={<NeedLandingPage />} />              
            </Route>
            <Route element={<FullLayout />} >
              <Route
                path="/plaza/dashboard"
                element={<AuthenticationGuard component={Dashboard} />}
              />
              <Route
                path="/plaza/profile"
                element={<AuthenticationGuard component={Profile} />}
              />
              <Route
                path="/plaza/subscription"
                element={<AuthenticationGuard component={Subscription} />}
              />
              <Route
                path="/plaza/freelancer"
                element={<AuthenticationGuard component={FreelancerAdmin} />}
              />
              <Route
                path="/plaza/freelancer-bookings"
                element={<AuthenticationGuard component={FreelancerBookings} />}
              />
              <Route
                path="/plaza/freelancer-createoffer"
                element={<AuthenticationGuard component={CreateOfferStandAlone} />}
              />
              <Route
                path="/plaza/client-bookings"
                element={<AuthenticationGuard component={ClientBookings} />}
              />
              <Route
                path="/plaza/freelancer-booking/:booking_request_id"
                element={<AuthenticationGuard component={FreelancerBooking} />}
              />
              <Route
                path="/plaza/client_needs"
                element={<AuthenticationGuard component={ClientNeeds} />}
              />
              <Route
                path="/plaza/client_need"
                element={<AuthenticationGuard component={ClientNeed} />}
              />
              <Route
                path="/plaza/create_need"
                element={<AuthenticationGuard component={PublishNeed} />}
              />
              <Route
                path="/plaza/offer_detail/:offer_id/:client_need_id/:freelancer_id"
                element={<AuthenticationGuard component={OfferDetail} />}
              />
              <Route
                path="/plaza/needs/:needId?"
                element={<AuthenticationGuard component={Needs} />}
              />
              <Route
                path="/plaza/need/:needId?"
                element={<AuthenticationGuard component={NeedDetails} />}
              />
              <Route
                path="/plaza/offers/:needId?"
                element={<AuthenticationGuard component={Offers} />}
              />
              <Route
                path="/plaza/freelancer-invoice/:offerId?"
                element={<AuthenticationGuard component={CreateAndUpdateInvoice} />}
              />
              <Route
                path="/plaza/freelancer-deals"
                element={<AuthenticationGuard component={ShowOffers} />}
              />
              <Route
                path="/plaza/freelancer-invoices"
                element={<AuthenticationGuard component={ShowInvoices} />}
              />


              {/* <Route
                path="/plaza/services"
                element={<AuthenticationGuard component={ProfileSetup} />}
              />
              <Route
                path="/plaza/skills"
                element={<AuthenticationGuard component={ProfileSetup} />}
              />
              <Route
                path="/plaza/salary"
                element={<AuthenticationGuard component={ProfileSetup} />}
              />
              <Route
                path="/plaza/invoices"
                element={<AuthenticationGuard component={ProfileSetup} />}
              />
              <Route
                path="/plaza/economy"
                element={<AuthenticationGuard component={ProfileSetup} />}
              /> */}
            </Route>
            <Route path="/terms-of-use" element={<RedirectToExternalUrl url="https://becausemondays.notion.site/Because-Mondays-Terms-of-use-b06cfdb99b554a7ea3c802201b5ddf1b" />} />
            <Route path="/privacy-policy" element={<RedirectToExternalUrl url="https://becausemondays.notion.site/Because-Mondays-Privacy-Policy-61ac7168767941f8a5bf6ff184a759d6" />} />
          </Routes>
      </ThemeProvider>
  );
}

export default App;
