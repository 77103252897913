import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
// const daymonApiServerUrl = "http://localhost:6061";
const daymonApiServerUrl = process.env.REACT_APP_DAYMON_ORIGIN_URL;


/*********************
 *  USER OPERATIONS
 ********************/
export const addUser = async (userData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/users`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
    data: userData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const createUserAndClient = async (userData, auth0Token) => {
  console.log("Sending: " + JSON.stringify(userData));
  const config = {
    url: `${apiServerUrl}/api/messages/userandclient`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
    data: userData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getUserByAuth0Id = async (auth0_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/users-byauth/${auth0_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const getUserAndClientByEmail = async (email, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/usersandclient-byemail/${email}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const searchUserAndClientByEmail = async (email, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/searchusers-byemail/${email}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const getUserByClientId = async (clientId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/user-byclientid/${clientId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};



export const updateUserDetails = async (user_id, personalinfo, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/users/${user_id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
    data: personalinfo,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const updateOfferUserDetails = async (user_id, clientUserData) => {
  const config = {
    url: `${apiServerUrl}/api/messages/users-offer/${user_id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: clientUserData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};



/*************************
 * SUBSCRIPTION OPREATIONS
 *************************/
export const getSubscriptions = async (type) => {
  const config = {
    url: `${apiServerUrl}/api/messages/subscriptions/${type}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getUserSubscriptions = async (user_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/user-subscriptions/${user_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const addUserSubscription = async (user_id, subscription_id, start_date, end_date, auth0Token) => {
  const userData = {
    user_id, 
    subscription_id, 
    start_date, 
    end_date
  };

  const config = {
    url: `${apiServerUrl}/api/messages/user-subscriptions`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
    data: userData, 
  };

  const { data, error } = await callExternalApi({ config });
  
  return {
    data: data || null,
    error,
  };
};

export const updateUserSubscription = async (id, user_id, subscription_id, start_date, end_date, auth0Token) => {
  const userData = {
    user_id, 
    subscription_id, 
    start_date, 
    end_date
  };

  const config = {
    url: `${apiServerUrl}/api/messages/user-subscriptions/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
    data: userData, 
  };

  const { data, error } = await callExternalApi({ config });
  
  return {
    data: data || null,
    error,
  };
};



/*************************
 * FREELANCER OPERATIONS
 *************************/
export const getFreelancers = async (randomize = false, limit) => {
  const randomizeQuery = randomize ? '?randomize=true' : '';
  const config = {
    url: `${apiServerUrl}/api/messages/freelancers${randomizeQuery}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getFreelancersBySkill = async (skillId) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancers-byskill/${skillId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getFreelancerById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getFreelancerByUserId = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancers_user/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const createFreelancer = async (freelancerData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancers`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: freelancerData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateFreelancer = async (id, freelancerData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancers/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: freelancerData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteFreelancer = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancers/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


/*******************************
 * FREELANCER SERVICES OPERATIONS
 *******************************/
export const getFreelancerServices = async (auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-services`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getFreelancerServicesByFreelancerId = async (id) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-services-freelancer_id/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getFreelancerServiceById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-services/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createFreelancerService = async (serviceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-services`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: serviceData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateFreelancerService = async (id, serviceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-services/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: serviceData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteFreelancerService = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-services/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/****************************
 * FREELANCER SKILLS OPERATIONS
 ****************************/
export const getSkills = async () => {
  const config = {
    url: `${apiServerUrl}/api/messages/skills`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getFreelancerSkills = async (freelancer_id) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-skills/${freelancer_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getFreelancerSkillById = async (id) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-skills/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createFreelancerSkill = async (skillData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-skills`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: skillData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteFreelancerSkill = async (freelancerId, skillId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-skills/${freelancerId}/${skillId}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/**************************
 * FOR TESTING 
 **************************/

export const getPublicResource = async () => {
  const config = {
    url: `${apiServerUrl}/api/messages/public`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getProtectedResource = async (auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/protected`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`, // Include the token in the request header
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAdminResource = async () => {
  const config = {
    url: `${apiServerUrl}/api/messages/admin`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


/*******************
 * CLIENT
 *******************/
export const getClientById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getClientByUserId = async (user_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client-byuserid/${user_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const createClient = async (user_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client/${user_id}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateClient = async (id, clientData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: clientData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteClient = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/*******************
 * BOOKINGS
 *******************/
export const getBookingById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getBookingByFreelancerId = async (freelancer_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking-byfreelancer_id/${freelancer_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getBookingByClientId = async (freelancer_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking-byclient_id/${freelancer_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const createBooking = async (bookingData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: bookingData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateBooking = async (id, bookingData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: bookingData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteBooking = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/*******************
 * OFFERS
 *******************/
export const getOfferById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getOfferByFreelancerId = async (freelancerId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-byfreelancerid/${freelancerId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getStandaloneOfferByFreelancerId = async (freelancerId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/standaloneoffer-byfreelancer_id/${freelancerId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getOfferByUId = async (unique_identifier, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offerbyuid/${unique_identifier}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getOfferByBookingRequestId = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offers-bybookingrequestid/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getOfferByClientNeedId = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offers-byclientneedid/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getOfferByClientNeedAndFreelancerId = async (client_need_id, freelancer_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offers-byclientneedandfreelancerid/${client_need_id}/${freelancer_id}`, 
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createOffer = async (offerData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: offerData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateOffer = async (id, offerData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: offerData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteOffer = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offers/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};




/*******************
 * OFFER PRICES
 *******************/

export const getPriceById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-price/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getPricesByOfferId = async (offerId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-prices-byofferid/${offerId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createPrice = async (priceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-price`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: priceData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updatePrice = async (id, priceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-price/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: priceData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deletePrice = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-price/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


/*******************
 * OFFER INVITATIONS
 *******************/
export const createOfferInvitation = async (invitationData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-invitations`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: invitationData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getOfferInvitationById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-invitations/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getOfferInvitationsByFreelancerId = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-invitations/by-freelancer/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getOfferInvitationsByClientId = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-invitations/by-client/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateOfferInvitation = async (id, invitationData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-invitations/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: invitationData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteOfferInvitation = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/offer-invitations/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/*******************
 * DEALS
 *******************/
export const getDealById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/deal/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getDealsByFreelancerId = async (freelancerId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/deal-byfreelancerid/${freelancerId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const createDeal = async (dealData) => {
  const config = {
    url: `${apiServerUrl}/api/messages/deal`,
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: dealData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateDeal = async (id, dealData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/deals/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: dealData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteDeal = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/deals/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/*******************
 * BOOKING MESSAGES
 *******************/
export const getBookingMessages = async (booking_request_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking-messages/${booking_request_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getBookingMessagesByClientNeedAndFreelancerId = async (client_need_id, freelancer_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking-cn-messages/${client_need_id}/${freelancer_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};




export const createBookingMessage = async (messageData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/booking-message`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: messageData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


/******************************
 * ANALYSE AND MATCH FREELANCER
 ******************************/

export const analyseFreelancerList = async (messageData, challenge) => {
  const config = {
    url: `${daymonApiServerUrl}/api/messages/analyse-freelancers`,
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: { messageData, challenge },  
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


/******************************
 * ANALYZE CV 
 ******************************/

export const analyseCV = async (pdfFile) => {
  const formData = new FormData();
  formData.append('pdf', pdfFile);

  const config = {
    url: `${daymonApiServerUrl}/api/messages/upload-cv-pdf`,
    method: "POST",
    data: formData,  
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/*******************
 * CLIENT NEED
 *******************/
export const getClientNeedById = async (id) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getClientNeeds = async (auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_needs`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getClientNeedByClientId = async (clientId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need_byclientid/${clientId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getClientNeedsByFreelancerId = async (freelancerId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need_byfreelancerid/${freelancerId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getClientNeedByOfferId = async (offerId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need_byofferid/${offerId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getClientNeedsAndSkillsByClientId = async (clientId, auth0Token) => {
  try {
    // Fetch all client needs by client ID
    const clientNeeds = await getClientNeedByClientId(clientId, auth0Token);
    // Fetch skills for each client need and add to the clientNeeds object
    for (let i = 0; i < clientNeeds.data.length; i++) {
      const skills = await getClientNeedSkills(clientNeeds.data.row[i].client_need_id, auth0Token);
      clientNeeds.skills = skills.data;
    }

    return clientNeeds;
  } catch (error) {
    throw error;
  }
};


export const createClientNeed = async (clientNeedData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: clientNeedData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateClientNeed = async (id, clientNeedData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: clientNeedData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteClientNeed = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

/*******************
 * CLIENT NEED SKILLS
 *******************/
export const getClientNeedSkills = async (clientNeedId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need_skills_byclientneedid/${clientNeedId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createClientNeedSkill = async (clientNeedSkillData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need_skills`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: clientNeedSkillData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateClientNeedSkill = async (id, clientNeedSkillData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need_skills/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: clientNeedSkillData,
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteClientNeedSkill = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/client_need_skills/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/*******************
 * SEARCH
 *******************/
export const freeSearch = async (searchterm, skill_name) => {
  const config = {
    url: `${apiServerUrl}/api/messages/search/?searchterm=${encodeURIComponent(searchterm)}&skill_name=${encodeURIComponent(skill_name)}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };
  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};



/*******************
 * INVOICES
 * invoiceType : FREELANCER | CLIENT
 * user_id is the user which this is sent to, used to calculate the service fees
 *******************/

export const getInvoiceById = async (id, user_id, invoiceType, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/invoice/${id}?user_id=${encodeURIComponent(user_id)}&invoiceType=${encodeURIComponent(invoiceType)}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


export const getInvoicesByFreelancerId = async (freelancerId, user_id, invoiceType, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/invoices-byfreelancer/${freelancerId}?user_id=${encodeURIComponent(user_id)}&invoiceType=${encodeURIComponent(invoiceType)}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getInvoicesByOfferId = async (offerId, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/invoices-byofferid/${offerId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};




export const getInvoicesByDealId = async (dealId, user_id, invoiceType, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/invoices-bydealid/${dealId}?user_id=${encodeURIComponent(user_id)}&invoiceType=${encodeURIComponent(invoiceType)}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createInvoice = async (invoiceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/invoice`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: invoiceData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateInvoice = async (id, invoiceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/invoice/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: invoiceData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteInvoice = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/invoice/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


/*************************
 * FREELANCER EXPERIENCE OPERATIONS
 *************************/

export const getFreelancerExperiences = async (freelancer_id) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-experience/${freelancer_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getFreelancerExperienceById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-experience-detail/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createFreelancerExperience = async (experienceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-experience`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: experienceData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateFreelancerExperience = async (id, experienceData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-experience/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: experienceData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteFreelancerExperience = async (id, auth0Token) => {
  console.debug("Deleting experience",id);
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-experience/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


/*************************
 * FREELANCER LANGUAGE OPERATIONS
 *************************/

export const getFreelancerLanguages = async (freelancer_id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-languages/${freelancer_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getFreelancerLanguageById = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-language-detail/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createFreelancerLanguage = async (languageData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-language`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: languageData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateFreelancerLanguage = async (id, languageData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-language/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: languageData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteFreelancerLanguage = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-language/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};


/*************************
 * FREELANCER EDUCATION OPERATIONS
 *************************/

export const getFreelancerEducations = async (freelancer_id) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-education/${freelancer_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const getFreelancerEducationById = async (id) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-education-detail/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const createFreelancerEducation = async (educationData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-education`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: educationData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const updateFreelancerEducation = async (id, educationData, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-education/${id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
    data: educationData,
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};

export const deleteFreelancerEducation = async (id, auth0Token) => {
  const config = {
    url: `${apiServerUrl}/api/messages/freelancer-education/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${auth0Token}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return { data: data || null, error };
};
